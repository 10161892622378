.inspection-text {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
}

.itext-800 {
	font-weight: 800;
}

.itext-600 {
	font-weight: 600;
}

.itext-500 {
	font-weight: 500;
}

.inspection-dark-blue {
	color: #181C32;
}

.inspection-dark {
	color: #000000;
}

.inspection-muted-text {
	color: #A2A5B5;
}

.inspection-accent-red {
	color: #D90909;
}

.inspection-text-14 {
	font-size: 14px;
}

.ifs-10 {
	font-size: 10px;
}

.ifs-13 {
	font-size: 13px;
}

.ifs-14 {
	font-size: 14px;
}

@include media-breakpoint-down(lg) { 
	.ifs-16 { 
		font-size:12px;
	}

}
@include media-breakpoint-up(lg) { 
	.ifs-16 {
		font-size: 16px;
	}
}


.ifs-18 {
	font-size: 18px;
}

.ifs-22 {
	font-size: 22px;
}

.ifs-23 {
	font-size: 23px;
}

.ifs-27 {
	font-size: 27px;
}

.ifs-28 {
	font-size: 28px;
}

.inspection-text-27 {
	font-size: 27px;
}

.inspection-field {
	font-family: 'Inter';
	font-weight: 600;
	color: #181C32;
	font-size: 14px;
	border: 1px solid #A2A5B5;
	border-radius: 9px;
}

.inspection-field::placeholder {
	font-family: 'Poppins';
	font-weight: 500;
	color: #B5B5C3;
	font-size: 13px;
}

.inspection-damage-btn {
	background: #F4F4F4;
	border-radius: 5px;
	min-width: 99px;
}

.inspection-btn {
	background: #F4F4F4;
	border-radius: 5px;
	min-width: 200px;
	max-width: 250px;
	height: 40px;
}

.form-label {
	height: 17px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: #A2A5B5;
}

.inspection-btn:hover {
	background: #D8D8D8;
}

.inspection-damage-btn:active {
	background: #D8D8D8;
}

@media only screen and (hover: none) and (pointer: coarse) {
	.element-to-hide {
		display: none;
	}
}

@media ( max-width : 900px) {
	.element-to-hide {
		display: none;
	}
}

.image-container {
	width: 100px;
	height: 100px;
	border-radius: 5px;
}

.select-field {
	max-width: 300px;
	height: 43px;
	background: #F4F4F4;
	border-radius: 6px;
}

.input-field {
	max-width: 300px;
	height: 43px;
	border-radius: 6px;
}

.textaria-field {
    min-height: 166px !important;
    max-width: 100%;
	border-radius: 6px;
}

.schema_coating {
	width: 225px !important;
	height: 250px !important;
}

.question-bool-btn {
	background: white !important;
	max-width: 350px;
	height: 38px;
    font-size: 14px;
	border-radius: 6px;
	border: 1.5px solid #D90909 !important;
}

input[type=number]:read-only  {
    -moz-appearance:textfield;
}